import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SecureLogo from './SecureLogo';
import LanguageSwitcher from './LanguageSwitcher';

function Header()
{
    return (
        <header className="py-3 border-bottom border-2">
            <Container>
                <Row>
                    <Col md={6} className="d-flex flex-column align-items-center align-items-lg-start justify-content-center">
                        <img style={{ width: "25%" }} src="/full-logo.png" />
                    </Col>

                    <Col md={6} className="d-flex flex-column align-items-center align-items-lg-end justify-content-center">
                        <SecureLogo showIRS="true" />
                    </Col>

                    <Col>
                        <LanguageSwitcher />
                    </Col>
                </Row>
            </Container>
        </header>
    );
}

export default Header;
