import React, { useEffect } from "react";
import { Row, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import { APP_BASE_URL, colors } from "../config/env";
import { estimaterSuccessLoadedEvent, claimTaxCreditEvent } from '../component/FacebookPixelEvents';


function EstimatorSuccess({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    useEffect(() =>
    {
        estimaterSuccessLoadedEvent();
    }, []);

    const claimCredits = (e) =>
    {
        e.preventDefault();

        claimTaxCreditEvent();

        const payload = {
            EstimatedValue: formData.EstimatedValue,
            salespersonId: formData.salespersonId
        };

        const token = btoa(JSON.stringify(payload));

        const url = `${APP_BASE_URL}/form?token=${token}`;
        const ref = window.open(url, '_blank');
    };

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`EstimatorSuccess.${key}`);
    };

    return (
        <div className="container">
            <h2 className="heading_emp" style={{ marginTop: 20, textAlign: "center" }}>
                {translate('EstimatedSETC')}
            </h2>

            <Row>
                <p style={{ textAlign: "center" }}>{translate('AccInfPrvYouQualify')}:</p>
            </Row>

            <Row>
                <h1 className="heading_emp" style={{ textAlign: "center" }}>{formatter.format(formData.EstimatedValue)}</h1>
            </Row>

            <Row>
                <p>{translate('ClaimYourSETC')}</p>
            </Row>

            <Row style={{ justifyContent: 'center', marginTop: '16px' }}>
                <Button
                    className="normal-button"
                    onClick={claimCredits}
                    style={{
                        width: "auto",
                        backgroundColor: colors.theme,
                        borderColor: colors.theme,
                        fontFamily: "unset",
                        fontWeight: "bold"
                    }}
                >
                    {translate('ClaimYourTaxCredit')}!
                </Button>
            </Row>

            <Row className="mt-4">
                <p><strong className='text-uppercase'>{commonTranslate('RefundDisclaimer')}:</strong> {commonTranslate('DisclaimerText')}</p>
            </Row>
        </div>
    );
}

export default EstimatorSuccess;