import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import DateDropDown from "../component/DateDropDown";
import { colors } from "../config/env";
import { setWithExpiry, getWithExpiry } from "../component/utility";
import { step5PartThreeSickLeaveCareYouRequiredLoadedEvent, step5PartThreeSickLeaveCareYouRequiredCompletedEvent } from '../component/FacebookPixelEvents';

function FormD({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const [error, setError] = useState({});

    useEffect(() =>
    {
        step5PartThreeSickLeaveCareYouRequiredLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    const submit = (e) =>
    {
        e.preventDefault();
        setError({});

        const initialError = {};

        if (!formData.S4_Q2 || formData.S4_Q2?.some((d) => !d))
        {
            initialError.S4_Q2 = "Please pick all dates";
        }

        if (Object.values(initialError).find((e) => e))
        {
            setError(initialError);
        }
        else
        {
            step5PartThreeSickLeaveCareYouRequiredCompletedEvent();

            const newActiveKey = +activeKey + 1;
            setActiveKey(newActiveKey);
            setWithExpiry("activeKey", newActiveKey, 50000);
            setWithExpiry("formData", formData, 50000);
        }
    };

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`FormD.${key}`);
    };

    return (
        <Form onSubmit={submit}>
            <Row>
                <div className="form-header">
                    <div className="form-title">
                        {translate('NumDaysAfterMar31st2021AndBeforeOct1st2021UnableToPerformServices')}

                        <p style={{ fontWeight: "bold", marginTop: "10px" }}>
                            {translate('SeeForInstructions')}
                        </p>
                    </div>
                </div>
            </Row>

            <Row className="date-select-picker">
                <DateDropDown
                    maxValue={10}
                    startDay={new Date("2021-04-02")}
                    endDay={new Date("2021-10-01")}
                    formData={formData}
                    onChange={(e) => setFormData({ ...formData, S4_Q2: e })}
                    error={error.S4_Q2}
                />
            </Row>

            <Row>
                <div className="form-button-group mt-3">
                    <Button
                        className="next-button"
                        type="button"
                        onClick={() =>
                        {
                            const newActiveKey = +activeKey - 1;
                            setActiveKey(newActiveKey);
                            setWithExpiry("activeKey", newActiveKey, 50000);
                        }}
                        style={{ backgroundColor: colors.back, borderColor: colors.back, width: 100 }}
                    >
                        {commonTranslate('Back')}
                    </Button>
                    <Button className="next-button text-uppercase" type="submit" style={{ backgroundColor: colors.theme, borderColor: colors.theme }}>
                        {commonTranslate('Next')}
                    </Button>
                </div>
            </Row>

            <Col className="mt-4 upper-divider italics">
                <p>{translate('SelectNumDayInPeriod')}</p>

                <ul>
                    <li>{translate('SubjectToFederalLocalOrIsolation')}</li>
                    <li>{translate('AdvisedByHealthCareProviderToSelfQuarantine')}</li>
                    <li>{translate('ExperiencingSymptomsOfCovid19')}</li>
                    <li>{translate('SeekingResultsOfDiagnostic')}</li>
                    <li>{translate('ExposedToCovid19')}</li>
                    <li>{translate('ObtainingImmunizationRelatedToCovid19')}</li>
                    <li>{translate('RecoveringFromAnyInjuryDisabilityIllness')}</li>
                </ul>
            </Col>
        </Form>
    );
}

export default FormD;