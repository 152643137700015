import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import { day50 } from "../component/helper";
import { colors } from "../config/env";
import { setWithExpiry, getWithExpiry } from "../component/utility";
import { step8PartTwoFamilyLeaveCareYouProvidedToDependentLoadedEvent, step8PartTwoFamilyLeaveCareYouProvidedToDependentCompletedEvent } from '../component/FacebookPixelEvents';

function FormG({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const [error, setError] = useState({});

    useEffect(() =>
    {
        step8PartTwoFamilyLeaveCareYouProvidedToDependentLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    const submit = (e) =>
    {
        e.preventDefault();
        setError({});

        const initialError = {};
        if (!formData.S4_Q1) initialError.S4_Q1 = "Please select value";

        if (Object.values(initialError).find((e) => e))
        {
            setError(initialError);
        }
        else
        {
            step8PartTwoFamilyLeaveCareYouProvidedToDependentCompletedEvent();

            const newActiveKey = +activeKey + 1;
            setActiveKey(newActiveKey);
            setWithExpiry("activeKey", newActiveKey, 50000);
            setWithExpiry("formData", formData, 50000);
        }
    };

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`FormG.${key}`);
    };

    return (
        <Form onSubmit={submit}>
            <Row>
                <div className="form-header">
                    <div className="form-title">
                        {translate('NumDaysAfterMar31st2021AndBeforeOct1st2021UnableToPerformServices')}

                        <p style={{ fontWeight: "bold", marginTop: "10px" }}>
                            {translate('SeeForInstructions')}
                        </p>
                    </div>
                </div>
            </Row>

            <Row className="date-select-picker">
                <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                    <Form.Select style={{ marginBottom: 20 }} onChange={(e) =>
                    {
                        const updatedFormData = { ...formData, S4_Q1: e.target.value };
                        setFormData(updatedFormData);
                        setWithExpiry("formData", updatedFormData, 50000);
                    }}>
                        <option>{commonTranslate('SelectDays')}</option>
                        {day50.slice().reverse().map((val, idx) =>
                        {
                            return <option key={idx} value={val}>{val}</option>;
                        })}
                    </Form.Select>
                    <p className="validation-error">{error.S4_Q1}</p>
                </div>
            </Row>

            <Row>
                <div className="form-button-group mt-3">
                    <Button
                        className="next-button"
                        type="button"
                        onClick={() =>
                        {
                            const newActiveKey = +activeKey - 1;
                            setActiveKey(newActiveKey);
                            setWithExpiry("activeKey", newActiveKey, 50000);
                        }}
                        style={{ backgroundColor: colors.back, borderColor: colors.back, width: 100 }}
                    >
                        {commonTranslate('Back')}
                    </Button>
                    <Button className="next-button" type="submit" style={{ backgroundColor: colors.theme, borderColor: colors.theme }}>
                        {commonTranslate('Next')}
                    </Button>
                </div>
            </Row>

            <Col className="mt-4 upper-divider italics">
                <p>{translate('NumDaysUnableToPerformFromJan1st2021ToMar31st2021')}</p>
                <p>{translate('SonOrDaughterMustGenerallyBeUnder18YearsOfAge')}</p>
            </Col>
        </Form>
    );
}

export default FormG;