import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import "./Landing.css";
import { ReactComponent as ChildIcon } from '../asset/dependent_icon.svg';
import { colors } from '../config/env';
import { setWithExpiry, getWithExpiry } from "../component/utility";
import { step7ClaimDependentLoadedEvent, step7ClaimDependentCompletedEvent } from '../component/FacebookPixelEvents';

function FormF({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    useEffect(() =>
    {
        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    useEffect(() =>
    {
        if (activeKey === 7)
        {
            step7ClaimDependentLoadedEvent();
        }
    }, [activeKey]);

    const handleClick = (answer) =>
    {
        if (answer === "no")
        {
            const updatedFormData = {
                ...formData,
                S4_Q1: 0,
                S5_Q1: 0,
                claimDependent: "no"
            };
            setFormData(updatedFormData);

            step7ClaimDependentCompletedEvent();

            const newActiveKey = +activeKey + 3;
            setActiveKey(newActiveKey);
            setWithExpiry("activeKey", newActiveKey, 50000);
            setWithExpiry("formData", updatedFormData, 50000);
        }
        else
        {
            const updatedFormData = {
                ...formData,
                claimDependent: "yes"
            };
            setFormData(updatedFormData);

            step7ClaimDependentCompletedEvent();

            const newActiveKey = +activeKey + 1;
            setActiveKey(newActiveKey);
            setWithExpiry("activeKey", newActiveKey, 50000);
            setWithExpiry("formData", updatedFormData, 50000);
        }
    };

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`FormF.${key}`);
    };

    return (
        <div className="initial-step-container">
            <Row className="text-center">
                <Col>
                    <ChildIcon className="svg-image-short-2" />
                </Col>
            </Row>
            <Row className="text-center mt-4">
                <Col>
                    <h2 className="heading_emp">{translate('Question6/6')}</h2>
                </Col>
            </Row>
            <Row className="text-center mt-3">
                <Col>
                    <p><strong>{translate('DependentsUnder18In2021')}</strong></p>
                </Col>
            </Row>
            <Row className="mt-3 button-row">
                <Col className="d-flex justify-content-center flex-wrap button-group">
                    <Button
                        className="next-button"
                        type="button"
                        onClick={() =>
                        {
                            const newActiveKey = +activeKey - 1;
                            setActiveKey(newActiveKey);
                            setWithExpiry("activeKey", newActiveKey, 50000);
                        }}
                        style={{ backgroundColor: colors.back, borderColor: colors.back, width: 100 }}
                    >
                        {commonTranslate('Back')}
                    </Button>
                </Col>
                <Col className="d-flex justify-content-center flex-wrap button-group">
                    <Button
                        variant="outline-secondary"
                        className="normal-button text-uppercase"
                        onClick={() => handleClick("yes")}
                    >
                        {commonTranslate('Yes')}
                    </Button>
                </Col>
                <Col className="d-flex justify-content-center flex-wrap button-group">
                    <Button
                        variant="outline-secondary"
                        className="normal-button text-uppercase"
                        onClick={() => handleClick("no")}
                    >
                        {commonTranslate('No')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default FormF;
