import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import LandingEstimator from './page/LandingEstimator';
import LandingForm from './page/LandingForm';
import ResumePage from './page/ResumePage';

function App()
{
  const RedirectWithQuery = ({ to }) =>
  {
    const location = useLocation();
    const queryParams = location.search;

    return <Navigate to={`${to}${queryParams}`} />;
  };


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/estimator" element={<LandingEstimator />} />
        <Route path="/form" element={<LandingForm />} />
        <Route path="/resume" element={<ResumePage />} />
        <Route path="*" element={<RedirectWithQuery to="/estimator" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;