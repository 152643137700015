import React, { useState, useEffect } from "react";
import { Row, Form, Button, Col, Alert, Modal } from "react-bootstrap";
import queryString from 'query-string';
import axios from "axios";
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';

import { colors } from "../config/env";

import { setWithExpiry } from "../component/utility";
import Question from '../component/Question';
import { day10, day50, day60 } from '../component/helper';
import FullScreenSpinner from '../component/FullScreenSpinner';
import { estimatorLoadedEvent, estimatedValueCalculatedEvent } from '../component/FacebookPixelEvents';

function Estimator({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const [error, setError] = useState({});
    const [showModal, setShowModal] = useState(false);

    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    useEffect(() =>
    {
        estimatorLoadedEvent();

        const parsed = queryString.parse(window.location.search);
        const salespersonId = parsed.salespersonId || '';
        setFormData(prevFormData => ({
            ...prevFormData, salespersonId,
            HasUnder18Dependents: 'Yes'
        }));
    }, []);

    const restartApplication = () =>
    {
        setActiveKey(+activeKey);
        handleClose();
    };

    const submit = async (e) =>
    {
        e.preventDefault();
        setError({});

        const errors = {};

        if (!formData.NetIncome2020) errors.NetIncome2020 = commonTranslate('FieldRequired');
        if (!formData.NetIncome2021) errors.NetIncome2021 = commonTranslate('FieldRequired');
        if (!formData.Jan1Mar31) errors.Jan1Mar31 = commonTranslate('SelectValue');
        if (!formData.Apr1Sep30) errors.Apr1Sep30 = commonTranslate('SelectValue');
        if (!formData.HasUnder18Dependents) errors.HasUnder18Dependents = commonTranslate('SelectAnswer');

        if (formData.HasUnder18Dependents === 'Yes')
        {
            if (!formData.DependentJan1Mar31) errors.DependentJan1Mar31 = commonTranslate('FieldRequired');
            if (!formData.DependentApr1Sep30) errors.DependentApr1Sep30 = commonTranslate('FieldRequired');
        }

        if (Object.values(errors).find((e) => e))
        {
            setError(errors);
            return;
        }
        try
        {
            setLoading(true);
            setErrorMessage(null);

            const uri = 'https://backend.automatedtaxcredits.com/api/v1/get_estimated_value';

            const payload = {
                net_income_20: +formData.NetIncome2020,
                net_income_21: +formData.NetIncome2021,
                number_of_days_no_work_covid_jan_1_mar_31: +formData.Jan1Mar31,
                number_of_days_no_work_covid_apr_1_sep_30: +formData.Apr1Sep30,
                number_of_days_no_work_care_dependent_jan_1_mar_1: 0,
                number_of_days_no_work_care_dependent_apr_1_sep_30: 0
            };

            if (formData.HasUnder18Dependents === 'Yes')
            {
                payload.number_of_days_no_work_care_dependent_jan_1_mar_1 = +formData.DependentJan1Mar31;
                payload.number_of_days_no_work_care_dependent_apr_1_sep_30 = +formData.DependentApr1Sep30;
            }

            const response = await axios.post(uri, payload, {
                headers: { 'Content-Type': 'application/json' }
            });

            setLoading(false);
            if (response.status !== 200)
            {
                console.log('Response: ', response);
                return;
            }

            estimatedValueCalculatedEvent();

            const estimatedValue = response.data.Estimated_Value;
            if (estimatedValue)
            {
                setFormData({ ...formData, EstimatedValue: estimatedValue });

                const newActiveKey = +activeKey + 1;
                setActiveKey(newActiveKey);
            }
            else
            {
                handleShow();
            }
        } catch (error)
        {
            setLoading(false);
            setErrorMessage(error.message);
            console.error("Error:", error.message);
        }
    };

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`Estimator.${key}`);
    };

    return (
        <>
            <div className="container">
                <h2 className="heading_emp" style={{ marginTop: 20, textAlign: "center" }}>
                    {translate('EstimateYourSETC')}
                </h2>

                <Form onSubmit={submit}>
                    <Row className="date-select-picker">
                        <Col lg={6} md={12}>
                            <Form.Group className="mb-3" style={{ marginTop: 30 }} controlId="clientName">
                                <Form.Label>
                                    <strong>{translate('2020SelfEmploymentNetIncome')}:</strong>
                                </Form.Label>
                                <CurrencyInput
                                    defaultValue={formData.NetIncome2020}
                                    decimalsLimit={2}
                                    onValueChange={(value, name) =>
                                    {
                                        setFormData({ ...formData, NetIncome2020: value });
                                    }}
                                    prefix="$"
                                    className="form-control"
                                />
                                <p className="validation-error">{error.NetIncome2020}</p>
                            </Form.Group>
                        </Col>

                        <Col lg={6} md={12}>
                            <Row className="date-select-picker">
                                <Form.Group className="mb-3" style={{ marginTop: 30 }} controlId="clientName">
                                    <Form.Label>
                                        <strong>{translate('2021SelfEmploymentNetIncome')}:</strong>
                                    </Form.Label>
                                    <CurrencyInput
                                        defaultValue={formData.NetIncome2021}
                                        decimalsLimit={2}
                                        onValueChange={(value, name) =>
                                        {
                                            setFormData({ ...formData, NetIncome2021: value });
                                        }}
                                        prefix="$"
                                        className="form-control"
                                    />
                                    <p className="validation-error">{error.NetIncome2021}</p>
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="date-select-picker">
                        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                            <label>
                                {translate('NumDaysNoWorkJan1stToMar31st')}
                            </label>
                            <Form.Select style={{ marginBottom: 20 }} onChange={(e) =>
                            {
                                const updatedFormData = { ...formData, Jan1Mar31: e.target.value };
                                setFormData(updatedFormData);
                                setWithExpiry("formData", updatedFormData, 50000);
                            }}>
                                <option>{commonTranslate('SelectDays')}</option>
                                {day10.slice().reverse().map((val, idx) =>
                                {
                                    return <option key={idx} value={val}>{val}</option>;
                                })}
                            </Form.Select>
                            <p className="validation-error">{error.Jan1Mar31}</p>
                        </div>
                    </Row>

                    <Row className="date-select-picker">
                        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                            <label>
                                {translate('NumDaysNoWorkApr1stToSep30th')}
                            </label>
                            <Form.Select style={{ marginBottom: 20 }} onChange={(e) =>
                            {
                                const updatedFormData = { ...formData, Apr1Sep30: e.target.value };
                                setFormData(updatedFormData);
                                setWithExpiry("formData", updatedFormData, 50000);
                            }}>
                                <option>{commonTranslate('SelectDays')}</option>
                                {day10.slice().reverse().map((val, idx) =>
                                {
                                    return <option key={idx} value={val}>{val}</option>;
                                })}
                            </Form.Select>
                            <p className="validation-error">{error.Apr1Sep30}</p>
                        </div>
                    </Row>

                    <Row>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Question
                                title={translate('DependentsUnder18In2021')}
                                groupName="HasUnder18Dependents"
                                value={formData.HasUnder18Dependents}
                                onChange={(e) =>
                                    setFormData({ ...formData, HasUnder18Dependents: e.target.value })
                                }
                            />
                            <p className="validation-error">{error.HasUnder18Dependents}</p>
                        </Form.Group>
                    </Row>

                    {formData.HasUnder18Dependents === 'Yes' && (
                        <Col>
                            <Row className="date-select-picker">
                                <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                                    <label>
                                        {translate('NumDaysNoWorkBcCareForDependentJan1stToMar31st')}
                                    </label>
                                    <Form.Select style={{ marginBottom: 20 }} onChange={(e) =>
                                    {
                                        const updatedFormData = { ...formData, DependentJan1Mar31: e.target.value };
                                        setFormData(updatedFormData);
                                        setWithExpiry("formData", updatedFormData, 50000);
                                    }}>
                                        <option>Select Days</option>
                                        {
                                            day50.slice().reverse().map((val, idx) =>
                                            {
                                                return <option key={idx} value={val}>{val}</option>;
                                            })
                                        }
                                    </Form.Select>
                                    <p className="validation-error">{error.DependentJan1Mar31}</p>
                                </div>
                            </Row>

                            <Row className="date-select-picker">
                                <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                                    <label>
                                        {translate('NumDaysNoWorkBcCareForDependentApr1stToSep30th')}

                                    </label>
                                    <Form.Select style={{ marginBottom: 20 }} onChange={(e) =>
                                    {
                                        const updatedFormData = { ...formData, DependentApr1Sep30: e.target.value };
                                        setFormData(updatedFormData);
                                        setWithExpiry("formData", updatedFormData, 50000);
                                    }}>
                                        <option>Select Days</option>
                                        {
                                            day60.slice().reverse().map((val, idx) =>
                                            {
                                                return <option key={idx} value={val}>{val}</option>;
                                            })
                                        }
                                    </Form.Select>
                                    <p className="validation-error">{error.DependentApr1Sep30}</p>
                                </div>
                            </Row>
                        </Col>
                    )}

                    {errorMessage && (
                        <Row>
                            <Alert variant="danger" onClose={() =>
                            {
                                setErrorMessage(null);
                            }} dismissible className="mt-3">
                                <Alert.Heading>Error</Alert.Heading>
                                <p>
                                    {errorMessage}
                                </p>
                            </Alert>
                        </Row>
                    )}

                    <Row style={{ justifyContent: 'center', marginTop: '16px' }}>
                        <Button
                            className="normal-button"
                            type="submit"
                            style={{
                                width: "auto",
                                backgroundColor: colors.theme,
                                borderColor: colors.theme,
                            }}
                        >
                            {translate('CalculateRefund')}
                        </Button>
                    </Row>

                    <Row className='mt-4'>
                        <p><strong className='text-uppercase'>{commonTranslate('RefundDisclaimer')}:</strong> {commonTranslate('DisclaimerText')}</p>
                    </Row>
                </Form>

                {loading && (
                    <Row>
                        <FullScreenSpinner />
                    </Row>
                )}
            </div>

            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Application Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Dear Applicant, we regret to inform you that after a thorough review
                        of your application, you do not qualify for the Self-Employed Tax
                        Credit.
                    </p>
                    <p>
                        If you believe this decision was made in error, you are welcome to
                        reapply. Should you have any questions or need further assistance,
                        please do not hesitate to contact our support team at{" "}
                        <a href="mailto:help@automatedtaxcredits.com">
                            help@automatedtaxcredits.com
                        </a>
                        .
                    </p>
                    <p>Please click here to reapply:</p>
                    <Button
                        className="next-button"
                        onClick={restartApplication}
                        style={{
                            backgroundColor: colors.theme,
                            borderColor: colors.theme,
                        }}
                    >
                        Restart Application
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Estimator;