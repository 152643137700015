/* eslint-disable jsx-a11y/iframe-has-title */
import { Button, Row, Alert } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import { colors, apiURL } from "../config/env";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { setWithExpiry, getWithExpiry } from '../component/utility';
import FullScreenSpinner from '../component/FullScreenSpinner';
import { step11PreQualifiedLoadedEvent, step11PreQualifiedCompletedEvent } from '../component/FacebookPixelEvents';

function FormSuccess({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() =>
    {
        step11PreQualifiedLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    const handleClick = async () =>
    {
        setLoading(true);
        setErrorMessage(null);

        try
        {
            const xano_uri = `https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/diysetc_zoho_status?email=${formData.Email}`;

            const resposne_xano = await axios.get(xano_uri);
            const zoho_record_id = resposne_xano.data.zoho_record_id;


            const zoho_uri = `${apiURL}/update_stage/${zoho_record_id}`;
            await axios.post(zoho_uri, { stage: 'IRS Authorization -Started' });

            const newActiveKey = +activeKey + 1;

            step11PreQualifiedCompletedEvent();

            setActiveKey(newActiveKey);
            setWithExpiry("activeKey", newActiveKey, 50000);
            setWithExpiry("formData", formData, 50000);

            setLoading(false);
        }
        catch (error)
        {
            setLoading(false);
            setErrorMessage(error.message);
        }
    };

    const translate = (key) =>
    {
        return t(`FormSuccess.${key}`);
    };

    return (
        <div className="container">
            <div className="content">
                <h2 className="heading_emp" style={{ marginTop: 40, textAlign: "center" }}>{translate('PreQualified')}!</h2>

                <div className="d-flex justify-content-center">
                    <iframe className="vimeo-iframe large-screen" src="https://player.vimeo.com/video/969103409?h=0f73829d6e&title=0&byline=0&portrait=0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                </div>

                <br></br>
                <br></br>

                <p style={{ textAlign: "center" }}>
                    {" "}
                    <strong>{translate('Congratulations')}!</strong> {translate('PleasedToInformYouHaveBeenPreQualified')}:
                </p>

                <ol className="mt-3">
                    <li className="mt-3"><p><strong>{translate('CompleteApplication')}:</strong>{translate('YouWillBeGuidedToFinishYourApplication')}</p></li>
                    <li className="mt-3"><p><strong>{translate('VerifyIdentification')}:</strong>{translate('DirectedToVerifyYourIdentification')}</p></li>
                    <li className="mt-3"><p><strong>{translate('IrsVerification')}:</strong>{translate('UponGrantingUsConsent')}</p></li>
                    <li className="mt-3"><p><strong>{translate('StayInformed')}:</strong>{translate('ThroughoutEntireProcess')}</p></li>
                </ol>

                <p>{translate('CompleteStepsRightNow')}</p>


                <Row style={{ justifyContent: 'center' }}>
                    <Button
                        className="normal-button"
                        onClick={handleClick}
                        style={{
                            width: "auto",
                            backgroundColor: colors.theme,
                            borderColor: colors.theme,

                        }}
                    >
                        {translate('FinishApplication')}
                    </Button>
                </Row>


                <br></br>
                <p>
                    {translate('WeAreHereToAssistYouAtEveryStep')}
                    <a href="mailto:help@automatedtaxcredits.com "> help@automatedtaxcredits.com </a>.
                </p>
                <br />
                <p>{translate('ThankYouForChoosingUs')}</p>


                {errorMessage && (
                    <Row>
                        <Alert variant="danger" onClose={() =>
                        {
                            setErrorMessage(null);
                        }} dismissible className="mt-3">
                            <Alert.Heading>Error</Alert.Heading>
                            <p>
                                {errorMessage}
                            </p>
                        </Alert>
                    </Row>
                )}

                <Row style={{ justifyContent: 'center', marginTop: "12px" }}>
                    <Button
                        className="normal-button"
                        onClick={handleClick}
                        style={{
                            width: "auto",
                            backgroundColor: colors.theme,
                            borderColor: colors.theme,

                        }}
                    >
                        {translate('FinishApplication')}
                    </Button>
                </Row>

                <br />

                {loading && (
                    <Row>
                        <FullScreenSpinner />
                    </Row>
                )}

            </div>
        </div>
    );
}

export default FormSuccess;
