import React, { useState, useRef, useEffect } from "react";
import { Row, Form, Button, ListGroup, Table, Col, Alert } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from 'react-i18next';

import "react-phone-input-2/lib/style.css";
import Misspeller from "../component/Misspeller";
import { colors, apiURL } from "../config/env";
import "../asset/css/custom-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { isEmpty } from '../component/helper';
import { setWithExpiry, getWithExpiry } from "../component/utility";
import FullScreenSpinner from '../component/FullScreenSpinner';
import { step10ClientServicesAgreementLoadedEvent, step10ClientServicesAgreementCompletedEvent } from '../component/FacebookPixelEvents';
import DatePicker from "react-datepicker";
function ClientServicesAgreement({ activeKey, setActiveKey, formData, setFormData, skipped })
{
    const { t } = useTranslation();

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
    const termsRef = useRef(null);
    const termsEndRef = useRef(null);

    useEffect(() =>
    {
        step10ClientServicesAgreementLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    // useEffect(() =>
    // {
    //     const scrollToBottom = () =>
    //     {
    //         const container = termsEndRef.current;
    //         if (container)
    //         {
    //             container.scrollTop = container.scrollHeight;
    //         }
    //     };

    //     activeKey === 10 && scrollToBottom();
    // }, [activeKey]);

    const handleBackClick = () =>
    {
        if (formData.claimDependent === "no")
        {
            setActiveKey(+activeKey - 3);
        }
        else
        {
            setActiveKey(+activeKey - 1);
        }
    };

    // const handleScroll = () =>
    // {
    //     const bottom = termsRef.current.scrollHeight - termsRef.current.scrollTop <= termsRef.current.clientHeight + 1;
    //     setIsScrolledToBottom(bottom);
    // };

    // useEffect(() =>
    // {
    //     if (termsRef.current)
    //     {
    //         termsRef.current.addEventListener('scroll', handleScroll);
    //     }
    //     return () =>
    //     {
    //         if (termsRef.current)
    //         {
    //             termsRef.current.removeEventListener('scroll', handleScroll);
    //         }
    //     };
    // }, []);

    useEffect(() =>
    {
        const currentDate = new Date().toISOString().split("T")[0];
        setFormData((prevFormData) => ({
            ...prevFormData,
            Effective_Date: currentDate,
            Client_Name: `${formData.First_Name} ${formData.Last_Name}`
        }));
    }, [setFormData, formData.First_Name, formData.Last_Name]);

    const handleAcceptTerms = () => setTermsAccepted(!termsAccepted);

    const listStyles = { border: "unset" };

    const submit = async (e) =>
    {
        e.preventDefault();
        setError({});

        const initialError = {};
        if (isEmpty(formData.Street_Address)) initialError.Street_Address = translate('EnterValueForField');
        if (isEmpty(formData.City)) initialError.City = translate('EnterValueForField');
        if (isEmpty(formData.State)) initialError.State = translate('EnterValueForField');
        if (isEmpty(formData.Zip_Code)) initialError.Zip_Code = translate('EnterValueForField');
        if (formData.primaryDOB === '')
        {
            initialError.primaryDOB = translate('EnterValueForField');
        }
        if (formData.TaxFilingStatus === 'Married Filing Jointly')
        {
            if (isEmpty(formData.SpouseFirstName)) initialError.SpouseFirstName = translate('EnterValueForField');
            if (isEmpty(formData.SpouseLastName)) initialError.SpouseLastName = translate('EnterValueForField');
            if (isEmpty(formData.SpouseSSN)) initialError.SpouseSSN = translate('EnterValueForField');
        }

        if (Object.keys(initialError).length > 0)
        {
            setError(initialError);
            return;
        }

        try
        {
            const S3_Q1_filedNames = await generateFieldNames(formData.S3_Q1, "S3_Q1");
            const S3_Q2_filedNames = await generateFieldNames(formData.S3_Q2, "S3_Q2");
            const S4_Q2_filedNames = await generateFieldNames(formData.S4_Q2, "S4_Q2");
            const S4_Q3_filedNames = await generateFieldNames(formData.S4_Q3, "S4_Q3");

            const primaryDOB = formData.primaryDOB
                ? new Date(formData.primaryDOB).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                })
                : '';

            const taxData = {
                s1Q1: formData.S1_Q1_Selfemployed === "Yes",
                s1Q2: formData.S1_Q2_Filed1040_tax === "Yes",
                s1Q3: formData.S1_Q3_Affected === "Yes",
                firstName: formData.First_Name,
                lastName: formData.Last_Name,
                email: formData.Email,
                phone: parseInt(formData?.Phone),
                s3Q1: formData?.S3_Q1?.length,
                s3Q2: formData.S3_Q2?.length,
                s4Q1: parseInt(formData?.S4_Q1),
                s4Q2: formData?.S4_Q2?.length,
                s4Q3: formData?.S4_Q3?.length,
                s5Q1: parseInt(formData?.S5_Q1),
                referralSource: formData.salespersonId,
                refereallURL: window.location.href,
                clientName: formData.Client_Name,
                effectiveDate: formData.Effective_Date,
                streetAddress: formData.Street_Address,
                city: formData.City,
                state: formData.State,
                zipCode: formData.Zip_Code,
                claimDependent: formData.claimDependent,
                stage: 'PreQualComplete',
                filingStatus: formData.TaxFilingStatus,
                Spouse_First_Name: formData.SpouseFirstName || '',
                Spouse_Last_Name: formData.SpouseLastName || '',
                Spouse_SSN: formData.SpouseSSN || '',
                primaryDOB
            };

            Object.assign(taxData, ...S3_Q1_filedNames);
            Object.assign(taxData, ...S3_Q2_filedNames);
            Object.assign(taxData, ...S4_Q2_filedNames);
            Object.assign(taxData, ...S4_Q3_filedNames);

            try
            {
                setLoading(true);

                const statePayload = {
                    "email": formData.Email,
                    "state": formData.State || "",
                    "dob": primaryDOB
                };

                await axios.patch("https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/diysetc_zoho_status", statePayload);

                const xano_uri = `https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/diysetc_zoho_status?email=${formData.Email}`;
                const resposne_xano = await axios.get(xano_uri);
                const zoho_record_id = resposne_xano.data.zoho_record_id;

                const response = await axios.post(`${apiURL}/update_record/${zoho_record_id}`, taxData);
                if (response.status === 200)
                {
                    setLoading(false);

                    step10ClientServicesAgreementCompletedEvent();

                    const newActiveKey = Number(activeKey) + 1;
                    setActiveKey(newActiveKey);
                    setWithExpiry("activeKey", newActiveKey, 50000);
                    setWithExpiry("formData", formData, 50000);
                }

            } catch (error)
            {
                console.error('Error:', error);
                setLoading(false);
                setErrorMessage(error.message);
            }

        } catch (error)
        {
            console.error("Error:", error);
        }
    };

    async function generateFieldNames(array, param)
    {
        const fieldNames = [];

        for (let i = 0; i < array?.length; i++)
        {
            const date = array[i];
            const dateString = formatDate(date);

            if (array.length === 1)
            {
                let fieldName;
                switch (param)
                {
                    case "S3_Q1":
                        fieldName = "S3_Q1_D1";
                        break;
                    case "S3_Q2":
                        fieldName = "S3_Q2_D1";
                        break;
                    case "S4_Q2":
                        fieldName = "S4_Q2_D1";
                        break;
                    case "S4_Q3":
                        fieldName = "S4_Q3_D1";
                        break;
                    default:
                        fieldName = `${param}_D1`;
                }
                fieldNames.push({ [fieldName]: dateString });
            } else
            {
                const tempObject = {};
                tempObject[`${param}_D${i + 1}`] = dateString;
                fieldNames.push(tempObject);
            }
        }

        return fieldNames;
    }

    function formatDate(date)
    {
        // Format date as mm/dd
        date = new Date(date);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${month}/${day}`;
    }

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const agreementTranslate = (key) =>
    {
        return t(`ClientServicesAgreement.UserAgreement.${key}`);
    };

    const translate = (key) =>
    {
        return t(`ClientServicesAgreement.${key}`);
    };

    return (
        <Form onSubmit={submit}>
            <Row>
                <Form.Group className="mb-3" style={{ marginTop: 30 }} controlId="clientName">
                    <Form.Label>
                        <strong>{translate('ClientName')}</strong>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={formData.Client_Name}
                        readOnly
                    />
                </Form.Group>
            </Row>

            <Row>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>
                        <strong>
                            {translate('Email')}*{" "}
                            <span
                                style={{ fontWeight: 100, fontStyle: "italic", fontSize: 14 }}
                            >
                                {translate('IsYourEmailCorrect')}
                            </span>
                        </strong>
                    </Form.Label>
                    <Misspeller
                        value={formData.Email}
                        onChange={(Email) => setFormData({ ...formData, Email })}
                    />
                    <p className="validation-error">{error.Email}</p>
                </Form.Group>
                <Col lg={6} md={12}>
                    <div>
                        <span>
                            <strong>{translate('PrimaryDateOfBirth')}</strong>
                        </span>
                    </div>

                    <DatePicker
                        className="form-control mb-3"
                        maxDate={new Date()}
                        selected={formData.primaryDOB ? new Date(formData.primaryDOB) : ''}
                        onChange={(date) => setFormData({ ...formData, primaryDOB: date })}
                        dropdownMode="select"
                        dateFormat="MM/dd/yyyy"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        minDate={new Date('1940-01-01')}
                    />
                    <p className="validation-error">{error.primaryDOB}</p>
                </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
                <Col lg={6} md={12}>
                    <strong style={{ marginBottom: 5 }}>{agreementTranslate('Phone')}* </strong>
                    <PhoneInput
                        country={"us"}
                        onlyCountries={["us"]}
                        value={formData.Phone} // Make sure formData.Phone is initialized properly
                        disableDropdown={true}
                        countryCodeEditable={false}
                        onChange={(e) => setFormData({ ...formData, Phone: e })}
                    />
                    <p className="validation-error">{error.Phone}</p>
                </Col>

                <Col lg={6} md={12}>
                    <div>
                        <span>
                            <strong>{translate('FilingStatus')}</strong>
                        </span>
                    </div>

                    <Form.Group className="mb-3" >
                        <Form.Select
                            onChange={(e) =>
                                setFormData({ ...formData, TaxFilingStatus: e.target.value })
                            }
                        >
                            <option value="Single">{translate('Single')}</option>
                            <option value="Married Filing Jointly">{translate('MarriedFilingJointly')}</option>
                            <option value="Married Filing Separately (MFS)">{translate('MarriedFilingSeparately')}</option>
                            <option value="Qualifying Widow (QW)">{translate('QualifyingWidow')}</option>
                            <option value="Head of Household (HOH)">{translate('HeadOfHousehold')}</option>
                            <option value="Not Sure">{translate('NotSure')}</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            {formData.TaxFilingStatus === 'Married Filing Jointly' && (
                <Row style={{ marginBottom: 10 }}>
                    <Col lg={6} md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>{translate('SpouseFirstName')}</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.SpouseFirstName}
                                onChange={(e) => setFormData({ ...formData, SpouseFirstName: e.target.value })}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>{translate('SpouseLastName')}</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.SpouseLastName}
                                onChange={(e) => setFormData({ ...formData, SpouseLastName: e.target.value })}
                            />
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label>{translate('SpouseSsn')}</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.SpouseSSN}
                                onChange={(e) => setFormData({ ...formData, SpouseSSN: e.target.value })}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            )}

            <Row>
                <Form.Group className="mb-3" controlId="streetAddress">
                    <Form.Label>
                        <strong>{translate('ClientStreetAddress')}*</strong>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={translate('StreetAddress')}
                        value={formData.Street_Address || ""}
                        onChange={(e) =>
                            setFormData({ ...formData, Street_Address: e.target.value })
                        }
                    />
                    <p className="validation-error">{error.Street_Address}</p>
                </Form.Group>
            </Row>

            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="city">
                        <Form.Label>
                            <strong>{translate('City')}*</strong>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={translate('City')}
                            value={formData.City || ""}
                            onChange={(e) =>
                                setFormData({ ...formData, City: e.target.value })
                            }
                        />
                        <p className="validation-error">{error.City}</p>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className="mb-3" controlId="state">
                        <Form.Label>
                            <strong>{translate('State')}*</strong>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={translate('State')}
                            value={formData.State || ""}
                            onChange={(e) =>
                                setFormData({ ...formData, State: e.target.value })
                            }
                        />
                        <p className="validation-error">{error.State}</p>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group className="mb-3" controlId="zipCode">
                        <Form.Label>
                            <strong>{translate('ZipCode')}*</strong>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={translate('ZipCode')}
                            value={formData.Zip_Code || ""}
                            onChange={(e) =>
                                setFormData({ ...formData, Zip_Code: e.target.value })
                            }
                        />
                        <p className="validation-error">{error.Zip_Code}</p>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <div
                    ref={termsRef}
                    style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px' }}
                >
                    <h5 className="heading_emp" style={{ textAlign: 'center' }}>{translate('TermsAndService')}</h5>
                    <p>Last Updated: September, 12, 2024</p>
                    <br></br>
                    <p>{agreementTranslate('ThisClientServicesAgreement')}</p>
                    <br></br>
                    <p> {agreementTranslate('InConsiderationOfMutualPromises')}:</p>
                    <br></br>

                    <ListGroup as="ol" numbered style={listStyles}>
                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('SERVICES')}
                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('TheClientHerebyExclusively')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('DuringTermOfContract')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('ClientAcknowledgesAndUnderstandsAtcRelyingUponInformationProvidedByClient')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('ClientAcknowledgesAndUnderstandsAtcWillNotProvide')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('UnlessOtherwiseSetForthInThisAgreement')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('PartiesAgreeThatAnyMaterialsOrInformationProvidedByAtcToTheClient')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('ClientAcknowledgesAndConsentsToExecutingAnIrs')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('ClientAcknowledgesAndConsentsToElectronicallyExecutingAnIrsForm')}</ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('TERM')}
                            <p>{agreementTranslate('TermOfAgreementShallCommenceOnEffectiveDate')}</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('FEES_AND_EXPENSES')}

                            <ListGroup as="ol" numbered>
                                <ListGroup.Item as="li" style={listStyles}>
                                    <span>{agreementTranslate('AfterClientReceivesTaxCreditAnalysisRelatedToCredits')}</span>

                                    <h5 className='mt-4 mb-4'>{agreementTranslate('TaxCreditAuditAnalysisFixedFeeServicePriceList')}: </h5>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>{agreementTranslate('TaxCreditAppliedToIrs')}</th>
                                                <th>{agreementTranslate('FixedFee')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{agreementTranslate('UpTo2k')}</td>
                                                <td>$250.00</td>
                                            </tr>
                                            <tr>
                                                <td>{agreementTranslate('2.001kTo6k')}</td>
                                                <td>$500.00</td>
                                            </tr>
                                            <tr>
                                                <td>{agreementTranslate('6.001kTo10k')}</td>
                                                <td>$1000.00</td>
                                            </tr>
                                            <tr>
                                                <td>{agreementTranslate('10.001kTo20k')}</td>
                                                <td>$1500.00</td>
                                            </tr>
                                            <tr>
                                                <td>{agreementTranslate('20.001kAndUp')}</td>
                                                <td>$2000.00</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    {/* <h5 className='mt-4 mb-4'>Post Pay % Based Price List: </h5>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th colSpan={3} className="text-center">Document Processing and Tax Form Preparation Fee</th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th>Description of qualification of fee</th>
                                                <th>Fee amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Pre-paid</td>
                                                <td>If a client owes the IRS more than or equal to ($) the calculated refund amount, ATC will request that the client pay’s ATC prior to signing and receiving their documents.         </td>
                                                <td>15% of total Tax Credit</td>
                                            </tr>
                                            <tr>
                                                <td>Post-paid</td>
                                                <td>If a client owes the IRS less than($) the calculated refund amount, and the remaining amount is more than or equal to 20% of the total refund amount, then the fee will be deducted from the refund amount.</td>
                                                <td>20% of total Tax Credit</td>
                                            </tr>
                                            <tr>
                                                <td>Document Processing Fee</td>
                                                <td>A document processing fee is applied to all accounts to pay the associated fees and charges for document preparation or fees associated with Esign and document analysis, or preparation. </td>
                                                <td>$99 one-time applied to all accounts. </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>If the client is not qualified to receive any tax credits, there will be no fees charged. </td>
                                                <td>No fees charged.</td>
                                            </tr>
                                        </tbody>
                                    </Table> */}

                                    <p>{agreementTranslate('PrepaidDefinedAsPayingYourAtcServiceFeesAtDocumentPreparation')}</p>
                                    <br></br>

                                    <p>{agreementTranslate('PostpaidDefinedAsPayingYourAtcServiceFeesFromYourIrsTaxCredit')}</p>
                                    <br></br>

                                    <p>{agreementTranslate('ForPointOfClarity')}</p>
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('ClientAgreesToPayFeesByAuthorizingAtcToDebitAchDirectly')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('ShouldClientFailToSignAndReturnToAtcSpecifiedDocuments')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('FeesNotPaidByClientWithinThirtyCalendarDays')}</ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('CLIENT_SELF_ATTESTATION')}

                            <p>{agreementTranslate('ClientAcknowledgesAndUnderstandsThatAtcIsRelyingOnInformationProvidedByClient')}</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('RELATIONSHIP_OF_THE_PARTIES')}

                            <p>{agreementTranslate('AtcIsAnIndependentContractorOfClient')}</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('IN_WITNESS_WHEREOF')}

                            <p>{agreementTranslate('PartiesHeretoHaveExecutedThisAgreement')}:</p>
                        </ListGroup.Item>
                    </ListGroup>

                    <p className="text-center" style={{ fontWeight: "bold" }}>{agreementTranslate('ScheduleOneTermsAndConditions')}</p>

                    <ListGroup as="ol" numbered style={listStyles}>
                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('CONFIDENTIALITY')}

                            <p>{agreementTranslate('AtcAcknowledgesThatAtcWillHaveAccessToInformation')}</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('REPRESENTATIONS_AND_WARRANTIES')}

                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>
                                    {agreementTranslate('AtcRepresentsAndWarrantsToClient')}:

                                    <ol type="a">
                                        <li>{agreementTranslate('ItHasFullRightPowerAndAuthority')};</li>
                                        <li>{agreementTranslate('EnteringIntoAgreementDoesNotAndWillNotConflictWithOrResult')};</li>
                                        <li>{agreementTranslate('AtcHadRequiredSkillExperienceAndQualificationsToPerformServices')}</li>
                                        <li>{agreementTranslate('ExecutionOfAgreementByItsRepresentativeWhoseSignatureIsSetForth')}</li>
                                    </ol>
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    {agreementTranslate('ClientHerebyRepresentsAndWarrantsToAtc')}:

                                    <ol type="a">
                                        <li>{agreementTranslate('WillProvideOnlyTruthfulInformationToAtc')}</li>
                                        <li>{agreementTranslate('WillUseItsBestEffortsToCooperateWithAtc')};</li>
                                        <li>{agreementTranslate('UponReasonableRequestByAtc')};</li>
                                        <li>{agreementTranslate('HasNotPreviouslyFiledToClaimCreditsWithIrs')}</li>
                                        <li>{agreementTranslate('EnteringIntoThisAgreementDoesNotAndWillNot')};</li>
                                        <li>{agreementTranslate('ItHasFullRightPowerAndAuthorityToEnterIntoThisAgreement')}</li>
                                        <li>{agreementTranslate('ExecutionOfAgreementByItsRepresentativeWhoseSignatureIsSetForth')}</li>
                                        <li>{agreementTranslate('IfClientProvidesOrDisclosesAnyServicesInformation')}</li>
                                    </ol>
                                </ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('COVENANTS')}

                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('ClientHerebyAgreesThatItWillUponReceiptOfAllNecessaryDocumentsFromAtc')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('ClientHerebyAgreesThatIfItFailsToReceiveItsCredits')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('ClientHerebyAgreesThatItWillPromptlyAndWithoutDelayNotifyAtc')}</ListGroup.Item>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('ClientHerebyAgreesToProvideNecessaryAuthorizations')}</ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('INDEMNIFICATION')}

                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('AtcIndemnificationDuties')}</ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    {agreementTranslate('ClientIndemnificationDuties')}:
                                    {agreementTranslate('BodilyInjuryDeathOfAnyPerson')};
                                    {agreementTranslate('ClientOwnershipAndOperationOfItsBusiness')}
                                    {agreementTranslate('ClientUseOfAnyServicesInformationProvidedByAtc')};
                                    {agreementTranslate('ClientBreachOfAnyContractual')};
                                    {agreementTranslate('ClientBreachOfAnyRepresentation')};
                                    {agreementTranslate('ClientFailureToTimelyPayFeesAsRequired')}
                                    {agreementTranslate('PartiesContractualRelationship')}
                                </ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('LIMITATION_OF_LIABILITY')}

                            <p>{agreementTranslate('NeitherPartyShallBeLiable')}</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('INSURANCE')}

                            <p>{agreementTranslate('DuringTermAtcShallMaintain')}</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            TERMINATION

                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>
                                    {agreementTranslate('ThisAgreementShallAutomaticallyTerminateUponCompletion')}:
                                    {agreementTranslate('NoFurtherFeesShallBePaidByClient')}
                                    {agreementTranslate('ClientShallPromptlyPayAllFeesContemplatedUnderAgreement')}
                                    {agreementTranslate('ATCShallPromptly')}:
                                    {agreementTranslate('DeliverToClientAllEquipment')}
                                    {agreementTranslate('DeliverToClientAllTangibleDocuments')};

                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>{agreementTranslate('TermsAndConditionsOfThisScheduleOneSectionSeven')}</ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('OTHER_BUSINESS_ACTIVITIES')}

                            <p>{agreementTranslate('AsAnIndependentContractor')}</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('ASSIGNMENT')}

                            <p>{agreementTranslate('ExceptAsOtherwiseDescribedInAgreement')}</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('COSTS_AND_EXPENSES')}

                            <p>{agreementTranslate('ClientAgreesThatAllCostsChargesAndExpenses')}</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('REMEDIES')}

                            <p>{agreementTranslate('AtcRequiredToInstituteAnySuitOrLegalAction')}</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('GOVERNING_LAW_JURISDICTION_AND_VENUE')}

                            <p>{agreementTranslate('AgreementAndAllRelatedDocumentsAreGovernedBy')}</p>
                        </ListGroup.Item>

                        <ListGroup.Item as="li" style={listStyles}>
                            {agreementTranslate('MISCELLANEOUS')}

                            <ListGroup as="ol" numbered style={listStyles}>
                                <ListGroup.Item as="li" style={listStyles}>
                                    {agreementTranslate('AllNoticesRequestsConsentsClaimsDdemandsWaivers')}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    {agreementTranslate('ThisAgreementTogetherWithAnyOtherDocumentsIncorporated')}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    {agreementTranslate('ThisAgreementMayOnlyBeAmendedModifiedOrSupplemented')}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    {agreementTranslate('IfAnyTermOrProvisionOfThisAgreementIsInvalid')}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    {agreementTranslate('EachOfPartiesHerebyWaivesRightToTrial')}
                                </ListGroup.Item>

                                <ListGroup.Item as="li" style={listStyles}>
                                    {agreementTranslate('BySigningThisAgreementYouConsentToReceiveTextMessages')}
                                </ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>
                    </ListGroup>

                    <h4>{agreementTranslate('MarketingAndCommunication')}</h4>
                    <p>{agreementTranslate('OptInConsent')}</p>
                    <p>{agreementTranslate('ByUsingOurServicesYouConsent')}</p>
                    <br></br>

                    <p>{agreementTranslate('PhoneCallsAndMessages')}</p>
                    <p ref={termsEndRef}>{agreementTranslate('YouAcknowledgeAndAgreeThatWeMayContact')}</p>
                </div>
            </Row>

            {isScrolledToBottom && (
                <Row className="mt-3">
                    <div
                        style={{
                            padding: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                            borderRadius: '4px',
                            display: 'inline-block',
                            backgroundColor: '#fff'
                        }}
                    >
                        <Col>
                            <h4>{translate('CheckboxToAccept')}:</h4>
                            <Form.Group controlId="termsCheckbox">
                                <Form.Check
                                    className='accept-terms-container'
                                    type="checkbox"
                                    label={
                                        <span>
                                            {translate('AcceptTermsAndConditions')} <a href="https://automatedtaxcredits.com/terms-and-conditions/" rel="noopener noreferrer" target="_blank">{translate('TermsOfService')}</a> {translate('and')} <a href="https://automatedtaxcredits.com/privacy-policy/" rel="noopener noreferrer" target="_blank">{translate('PrivacyPolicy')}</a>.
                                        </span>
                                    }
                                    checked={termsAccepted}
                                    onChange={(e) => setTermsAccepted(e.target.checked)}
                                />
                            </Form.Group>
                        </Col>
                    </div>
                </Row>
            )}

            {errorMessage && (
                <Row>
                    <Alert variant="danger" onClose={() =>
                    {
                        setErrorMessage(null);
                    }} dismissible className="mt-3">
                        <Alert.Heading>{commonTranslate('Error')}</Alert.Heading>
                        <p>
                            {errorMessage}
                        </p>
                    </Alert>
                </Row>
            )}

            <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                <div className="form-button-group">
                    <Button
                        className="next-button"
                        type="button"
                        onClick={handleBackClick}
                        style={{ backgroundColor: colors.back, borderColor: colors.back, width: 100 }}
                    >
                        {commonTranslate('Back')}
                    </Button>
                    <Button className="next-button text-uppercase" type="submit" disabled={!termsAccepted} style={{ backgroundColor: colors.theme, borderColor: colors.theme }}>
                        {commonTranslate('Next')}
                    </Button>
                </div>
            </Row>

            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}

        </Form>
    );
}

export default ClientServicesAgreement;