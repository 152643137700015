import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Alert, Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import axios from "axios";
import { emailRegex, isEmpty } from "../component/helper";
import { colors } from "../config/env";
import FullScreenSpinner from "../component/FullScreenSpinner";
import { useNavigate } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';

function ResumePage({ setFormData, setActiveKey })
{
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) =>
    {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (isEmpty(email) || !emailRegex.test(email))
        {
            setError("Please enter a valid email address.");
            setLoading(false);
            return;
        }

        try
        {
            const response = await axios.get(
                `https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/get_user_progress?email=${email}`
            );

            if (response.status === 200)
            {
                const token = response.data.token;
                navigate(`/form?token=${token}`, {
                    state: {
                        email: email
                    }
                });
            }
            else
            {
                console.log("email not found");
            }
        }
        catch (error)
        {
            console.log('Server Error: ', error);
            setError("An error occurred while fetching your data. Please try again.");
        }
        finally
        {
            setLoading(false);
        }
    };

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`Resume.${key}`);
    };

    return (
        <Container className="text-center">
            <Header />

            <div className="container mb-5">
                <div className="content">
                    <h2 className="heading_emp" style={{ marginTop: 40, textAlign: "center" }}>{translate('ResumeProgress')}!</h2>

                    <br /><br />

                    <Form onSubmit={handleSubmit} className="d-flex justify-content-center">
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                {/* <div className="d-flex flex-row align-items-center gap-4"> */}
                                <Form.Label><strong>{translate('EmailToFindProgress')}</strong></Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder={commonTranslate('EnterEmail')}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {/* </div> */}
                            </Form.Group>

                            {error && (
                                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                                    {error}
                                </Alert>
                            )}

                            <Row className="my-2 mx-1">
                                <Button
                                    className="normal-button"
                                    type="submit"
                                    disabled={loading}
                                    style={{
                                        width: "100%",
                                        backgroundColor: colors.theme,
                                        borderColor: colors.theme,
                                    }}
                                >
                                    {commonTranslate('Submit')}
                                </Button>
                            </Row>

                        </Col>
                    </Form>
                </div>
            </div>

            {loading && (
                <Row>
                    <FullScreenSpinner />
                </Row>
            )}

            <Footer />
        </Container >
    );
}

export default ResumePage;