import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { getWithExpiry } from '../component/utility';
import SecureLogo from '../component/SecureLogo';
import { step15LetUsGetToWorkNowLoadedEvent } from '../component/FacebookPixelEvents';

function FinalSuccessPage({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    useEffect(() =>
    {
        step15LetUsGetToWorkNowLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    const translate = (key) =>
    {
        return t(`FinalSuccessPage.${key}`);
    };

    return (
        <div className="container">
            <div className="content">
                <h2 className="heading_emp" style={{ marginTop: 40, textAlign: "center" }}>{translate('WellDone')}!</h2>
                {/* <h2 className="heading_emp" style={{ marginTop: 40, textAlign: "center" }}>Well Done… Let Us Get to Work Now!</h2> */}

                <div className="d-flex justify-content-center">
                    <iframe className="vimeo-iframe large-screen" src="https://player.vimeo.com/video/969195795?h=e4f78a2328&title=0&byline=0&portrait=0" width="640" height="360" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                </div>

                <br></br>
                <br></br>
                <p>{translate('ThankYouForCompletingIntakeProcess')}</p>
                <br></br>
                <p>{translate('OurTeam')}</p>
                <br></br>
                <p>{translate('Remember')}<strong>{translate('UpTo15Days')}</strong>{translate('ToOurTeamToAnalyze')}</p>
                <br></br>
                <p>{translate('OnceWeHaveYourIrsTranscripts')}</p>
                <br></br>
                <p>{translate('OurTeamWillPresentYourAvailableCredits')}</p>
                <br></br>
                <p>{translate('WeConfirmYouHaveTaxCreditsAvailable')}</p>

                <br></br>
                <h3>{translate('HowLongToReceiveTaxCredits')}</h3>
                <p>{translate('TakeUpTo15Weeks')}</p>

                <br></br>
                <h3>{translate('SetcFilingCost')}</h3>
                <p>{translate('DocumentProcessingFee')}</p>
                <p>{translate('TaxCreditSuccessFee')}</p>

                <br></br>
                <p>{translate('CheckingYourEligibility')}:</p>
                <ul>
                    <li>{translate('NoUpfrontCosts')}</li>
                    <li>{translate('FeeUponSuccessfulRefund')}</li>
                    <li>{translate('SecurePayments')}</li>
                </ul>

                <br></br>
                <h4>{translate('WhenDoIPay')}</h4>
                <p>{translate('YouWillPayFeeAfterSuccessfulSetc')}</p>

                <br></br>
                <h4>{translate('WillThereBeProblem')}</h4>
                <p>{translate('OurSystemWillRetrieveYourTaxData')}</p>

                <br></br>
                <h4>{translate('HowMuchICharged')}</h4>
                <p>{translate('IfYouDoNotOweAnyBackTaxes')}</p>

                <br></br>
                <p>{translate('IfYouOweAnyBackTaxes')}</p>

                <br></br>
                <h4>{translate('HowLongUntilGetMySetcRefund')}</h4>
                <p><strong>{translate('InitialProcessing')}: </strong>{translate('AfterSubmittingYourApplication')}</p>

                <br></br>
                <p><strong>{translate('RefundNotification')}:</strong>{translate('OnceWeCalculateYourRefundAmount')}</p>

                <br></br>
                <p><strong>{translate('YouDoNotHaveBalanceWithIrs')}</strong> – {translate('WeWillChargeAfterYouReceiveRefund')}</p>

                <br></br>
                <p><strong>{translate('YouDoHaveBalanceWithIrs')}</strong> – {translate('PrepaidInvoiceAtDiscountedAmount')}</p>

                <br></br>
                <p><strong>{translate('IrsProcessing')}:</strong>{translate('EmailWillSentAllowingYouAccessAndPrintNecessaryIrsForms')}</p>

                <br></br>
                <p><strong>{translate('RefundDelivery')}:</strong>{translate('BasedOnIrsInformation')}</p>

                <br></br>
                <div className="d-flex justify-content-center">
                    <SecureLogo />
                </div>
            </div>
        </div>
    );
}

export default FinalSuccessPage;