import React from "react";
import DatePicker from "react-datepicker";
import { ReactComponent as CalendarIcon } from "../asset/calendar-11012.svg";
import "react-datepicker/dist/react-datepicker.css";
import "../asset/css/custom-calendar.css";

const CustomCalendar = ({ setHighlightedDate, highlightedDate, title, index, start, end, error }) =>
{
  const datePickerRef = React.useRef(null);

  const viewCalendar = (e) =>
  {
    datePickerRef.current.setFocus();
  };
  const calendarItem = {
    padding: "0px 10px 0px 10px",
  };
  const calendarDiv = {
    display: "inline-flex",
    flexWrap: "wrap",
  };
  const datePickerDiv = {
  };
  const calendarIconDiv = {
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
  };
  const datePlaceholder = {
    fontSize: "11px",
    color: "#888888",
  };
  const dateLabel = {
    fontSize: "15px",
    color: "#222222",
    paddingBottom: "20px",
  };

  return (
    <div style={calendarItem}>
      <div>
        <span style={dateLabel}>
          <strong>{title}</strong>
        </span>
      </div>
      <div style={calendarDiv}>
        <div style={datePickerDiv}>
          <DatePicker
            ref={datePickerRef}
            minDate={start}
            maxDate={end}
            openToDate={start}
            highlightDates={highlightedDate.filter(v => v)}
            startDate={start}
            excludeDates={highlightedDate.filter(v => v)}
            selected={highlightedDate[index] ? new Date(highlightedDate[index]) : null}
            onChange={(date) => setHighlightedDate(highlightedDate.map((v, i) => i === index ? date : v))}
            dropdownMode="select"
          />
          <div></div>
        </div>
        <div style={calendarIconDiv}>
          <CalendarIcon onClick={viewCalendar} />
        </div>
      </div>
      <div>
        <span style={datePlaceholder}>MM/dd/yyyy</span>
      </div>
      <p className="validation-error">{error ? 'Please pick date' : ''}</p>
    </div>
  );
};

export default CustomCalendar;
