import React, { useEffect } from "react";
import { Row, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import "react-phone-input-2/lib/style.css";
import "./Landing.css";
import { colors } from '../config/env';
import { setWithExpiry, getWithExpiry } from '../component/utility';
import { step14ESignAuthorizationFormLoadedEvent, step14ESignAuthorizationFormCompletedEvent } from '../component/FacebookPixelEvents';

function ESignInstructions({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    useEffect(() =>
    {
        step14ESignAuthorizationFormLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }

    }, [setFormData]);

    const translate = (key) =>
    {
        return t(`ESignInstructions.${key}`);
    };

    return (
        <div className="container">
            <div className="content">
                <h2 className="heading_emp" style={{ marginTop: 40, textAlign: "center" }}>{translate('InstantlyESignYourTranscript')}!</h2>

                <div className="mt-4 d-flex justify-content-center">
                    <iframe className="vimeo-iframe large-screen" src="https://player.vimeo.com/video/969103956?h=885a5ac128&title=0&byline=0&portrait=0" width="640" height="360" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                </div>
                <br></br>

                <p style={{ textAlign: "center", marginTop: "12px" }}>
                    {translate('WeNeedToPullYourIrsTranscripts')}
                </p>

                <p style={{ textAlign: "center", marginTop: "12px" }}>
                    {translate('FormWillBeInstantlyEmailed')}
                </p>

                <p style={{ textAlign: "center", marginTop: "12px" }}>
                    {translate('YouCanSendThisFormViaEmail')}
                </p>

                <p style={{ textAlign: "center", marginTop: "12px" }}>
                    {translate('WeWillUpdateYouViaEmail')}
                </p>

                <h2 className="heading_emp" style={{ marginTop: 40, textAlign: "center" }}>{translate('PleaseCheckYourEmail')}!</h2>

                <ul>
                    <li>{translate('CheckYourEmailForDocumentToSign')}</li>
                    <li>{translate('CheckYourSpamFolder')}</li>
                    <li>{translate('Take15MinutesToReceiveEmail')}</li>
                    <li>{translate('ReceiveDocumentLinkViaSms')}</li>
                    <li>{translate('LookForEmailWithSubject')}: <strong>{translate('SignatureRequest')}</strong></li>
                    <li>{translate('ClickButtonInsideEmail')}</li>
                </ul>

                <p>{translate('OnceYouSignTheDocument')}</p>

                <Row style={{ justifyContent: 'center', marginTop: '12px' }}>
                    <Button
                        className="normal-button"
                        style={{
                            width: "auto",
                            backgroundColor: colors.theme,
                            borderColor: colors.theme,

                        }}
                        onClick={() =>
                        {
                            step14ESignAuthorizationFormCompletedEvent();
                            setActiveKey(Number(activeKey) + 1);
                        }}
                    >
                        {translate('ESignedThisAuthorization')}
                    </Button>
                </Row>
            </div>
        </div>
    );
}

export default ESignInstructions;