import * as React from "react";
import { useTranslation } from 'react-i18next';

import { setWithExpiry, getWithExpiry } from '../component/utility';
import { step12GovernmentIdentificationLoadedEvent } from '../component/FacebookPixelEvents';
import { Veriff } from '@veriff/js-sdk';


function DLUploader({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const translate = (key) =>
    {
        return t(`DLUploader.${key}`);
    };

    React.useEffect(() =>
    {
        step12GovernmentIdentificationLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    React.useEffect(() =>
    {

        const veriff = Veriff({
            apiKey: process.env.REACT_APP_VERIFF_API_KEY,
            parentId: 'veriff-root',
            onSession: function (err, response)
            {
                console.log(response);
                window.location.replace(response.verification.url);
            }
        });
        veriff.setParams({
            person: {
                givenName: ' ',
                lastName: ' ',
            },
            vendorData: `${formData.Email}`
        });
        veriff.mount({
            submitBtnText: translate('GetVerified'),
            loadingText: translate('PleaseWait')
        });

    }, [formData.Email, formData.First_Name, formData.Last_Name]);

    return (
        <div style={{ marginTop: '2.5rem' }}>
            <h3>{translate('WeUseVeriffForIdVerification')}</h3>
            <div id='veriff-root' style={{ margin: 'auto', marginTop: '2rem' }}></div>
        </div>

    );
}

export default DLUploader;
