import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import Question from "../component/Question";
import { isEmpty } from "../component/helper";
import "react-phone-input-2/lib/style.css";
import "./Landing.css";
import { colors } from "../config/env";
import { setWithExpiry, getWithExpiry } from '../component/utility';
import { step1IntakeFormLoadedEvent, step1IntakeFormCompletedEvent } from '../component/FacebookPixelEvents';

function FormFirst({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const [error, setError] = useState({});
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`FirstForm.${key}`);
    };

    useEffect(() =>
    {
        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    useEffect(() =>
    {
        if (activeKey === 1)
        {
            step1IntakeFormLoadedEvent();
        }
    }, [activeKey]);

    const submit = (e) =>
    {
        e.preventDefault();
        setError({});

        const initialError = {};

        if (isEmpty(formData.S1_Q4_OweIrsMoney))
        {
            initialError.S1_Q4_OweIrsMoney = commonTranslate('SelectAnswer');
        }

        if (isEmpty(formData.S1_Q1_Selfemployed))
        {
            initialError.S1_Q1_Selfemployed = commonTranslate('SelectAnswer');
        }

        if (isEmpty(formData.S1_Q2_Filed1040_tax))
        {
            initialError.S1_Q2_Filed1040_tax = commonTranslate('SelectAnswer');
        }

        if (isEmpty(formData.S1_Q3_Affected))
        {
            initialError.S1_Q3_Affected = commonTranslate('SelectAnswer');
        }

        if (Object.values(initialError).find((e) => e))
        {
            setError(initialError);
        }
        else if (formData.S1_Q3_Affected === "No")
        {
            handleShow();
        }
        else
        {
            step1IntakeFormCompletedEvent();
            const newActiveKey = +activeKey + 1;
            setActiveKey(newActiveKey);
            setWithExpiry("activeKey", newActiveKey, 50000);
            setWithExpiry("formData", { ...formData }, 50000);
        }
    };

    const restartApplication = () =>
    {
        setActiveKey(+activeKey);
        handleClose();
    };

    return (
        <>
            <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Question
                        title={translate('DoYouHaveTaxDebtOrOweIrsMoney')}
                        groupName="S1_Q4_OweIrsMoney"
                        value={formData.S1_Q4_OweIrsMoney}
                        onChange={(e) =>
                            setFormData({ ...formData, S1_Q4_OweIrsMoney: e.target.value })
                        }
                    />
                    <p className="validation-error">{error.S1_Q4_OweIrsMoney}</p>

                    <Question
                        title={translate('SelfEmployed')}
                        groupName="S1_Q1_Selfemployed"
                        value={formData.S1_Q1_Selfemployed}
                        onChange={(e) =>
                            setFormData({ ...formData, S1_Q1_Selfemployed: e.target.value })
                        }
                    />
                    <p className="validation-error">{error.S1_Q1_Selfemployed}</p>

                    <Question
                        title={translate('SmallBusinessOwner')}
                        groupName="S1_Q2_Filed1040_tax"
                        value={formData.S1_Q2_Filed1040_tax}
                        onChange={(e) =>
                            setFormData({ ...formData, S1_Q2_Filed1040_tax: e.target.value })
                        }
                    />
                    <p className="validation-error">{error.S1_Q2_Filed1040_tax}</p>

                    <Question
                        title={translate('AffectedByCovid')}
                        groupName="S1_Q3_Affected"
                        value={formData.S1_Q3_Affected}
                        onChange={(e) =>
                            setFormData({ ...formData, S1_Q3_Affected: e.target.value })
                        }
                    />
                    <p className="validation-error">{error.S1_Q3_Affected}</p>

                    <div className="next-button-div">
                        <Button
                            className="next-button text-uppercase"
                            type="submit"
                            style={{
                                backgroundColor: colors.theme,
                                borderColor: colors.theme
                            }}
                        >
                            {commonTranslate('Next')}
                        </Button>
                    </div>
                </Form.Group>
            </Form>

            <Col className="mt-4">
                <h4>{commonTranslate('IntakeQuestionFaq')}: </h4>

                <p>{translate('ReferAdditionalInformation')}</p>

                <p className="mt-4"><b>{translate('EligibleSelfEmployedIndividual')} </b></p>

                <p className="mt-4">{translate('YouMustBeAnEligibleSelfEmployedIndividual')}</p>

                <ol className="mt-4">
                    <li>{translate('YouRegularlyCarriedTradeOrBusiness')}</li>
                    <li>{translate('YouWouldHaveBeenEligible')}</li>
                </ol>

                <p className="mt-4">{translate('IfAnswerYes')}</p>
                <p className="mt-4">{translate('IfInDoubt')}</p>

                <p className="mt-4"><b>{translate('RegularlyCarriedTradeOrBusiness')}</b>{translate('AnIndividualRegularlyCarriesTradeOrBusiness')}</p>
                <p className="mt-4"><b>{translate('EligibilityToReceiveQualifiedWages')}</b>{translate('ForPart1')}</p>

                <ul>
                    <li>{translate('SubjectToFederalLocalOrIsolation')}</li>
                    <li>{translate('AdvisedByHealthCareProviderToSelfQuarantine')}</li>
                    <li>{translate('ExperiencingSymptomsOfCovid19')}</li>
                    <li>{translate('CaringAnIndividualSubjectToFederalLocalOrIsolation')}</li>
                    <li>{translate('CaringAnIndividualAdvisedByHealthCareProviderToSelfQuarantine')}</li>
                    <li>{translate('CaringSonOrDaughter')}</li>
                </ul>

                <p className="mt-4"><b>{translate('ForPartTwo')},</b>{translate('UnableToPerformServicesAsSelfEmployedBwJan1stToMar31st')}</p>
                <ul>
                    <li>{translate('CaringSonOrDaughter')}</li>
                </ul>

                <p className="mt-4"><b>{translate('ForPartsThreeAndFour')},</b> {translate('UnableToPerformServicesAsSelfEmployedBwApr1stToSep30th')} </p>
                <ul>
                    <li>{translate('SubjectToFederalLocalOrIsolation')}</li>
                    <li>{translate('AdvisedByHealthCareProviderToSelfQuarantine')}</li>
                    <li>{translate('ExperiencingSymptomsOfCovid19')}</li>
                    <li>{translate('SeekingResultsOfDiagnostic')}</li>
                    <li>{translate('ExposedToCovid19')}</li>
                    <li>{translate('ObtainingImmunizationRelatedToCovid19')}</li>
                    <li>{translate('RecoveringFromAnyInjuryDisabilityIllness')}</li>
                    <li>{translate('CaringAnIndividualSubjectToFederalLocalOrIsolation')}</li>
                    <li>{translate('CaringAnIndividualAdvisedByHealthCareProviderToSelfQuarantine')}</li>
                    <li>{translate('CaringSonOrDaughter')}</li>
                    <li>{translate('AccompanyingAnIndividualToObtainImmunization')}</li>
                    <li>{translate('CaringAnIndividualRecoveringFromAnyInjuryDisabilityIllness')}</li>
                </ul>

                <p className="mt-4"><b>{translate('SelfEmployedStatus')}:</b></p>
                <p className="mt-4">{translate('SelfEmployedIn2021')}</p>
                <p className="mt-4">{translate('YouFindIncomeOnLine6OfYourScheduleSE')}</p>
                <p className="mt-4">{translate('Line6LooksLikeThis')}: </p>

                <img className="w-100" src="/Line6Example1.png" />

                <h4 className="mt-4">{translate('CovidImpacts')}: </h4>

                <p className="mt-4">{translate('YouBattledCovidExperiencedCovid')}</p>

            </Col>


            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{translate('ApplicationReview')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{translate('RegretToInformYou')}</p>
                    <p>
                        {translate('Reapply')}{" "}
                        <a href="mailto:help@automatedtaxcredits.com">
                            help@automatedtaxcredits.com
                        </a>
                        .
                    </p>
                    <p>{translate('ClickToReapply')}:</p>
                    <Button
                        className="next-button"
                        onClick={restartApplication}
                        style={{
                            backgroundColor: colors.theme,
                            borderColor: colors.theme,
                        }}
                    >
                        {translate('RestartApplication')}
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default FormFirst;
