import { Form, Row, Col, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { day60 } from "../component/helper";
import { colors } from '../config/env';
import { setWithExpiry, getWithExpiry } from "../component/utility";
import { step9PartFourFamilyLeaveCareYouRequiredOrProvidedLoadedEvent, step9PartFourFamilyLeaveCareYouRequiredOrProvidedCompletedEvent } from '../component/FacebookPixelEvents';

function FormH({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const [error, setError] = useState({});

    useEffect(() =>
    {
        step9PartFourFamilyLeaveCareYouRequiredOrProvidedLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    const submit = (e) =>
    {
        e.preventDefault();
        setError({});

        step9PartFourFamilyLeaveCareYouRequiredOrProvidedCompletedEvent();

        const newActiveKey = +activeKey + 1;
        setActiveKey(newActiveKey);
        setWithExpiry("activeKey", newActiveKey, 50000);
        setWithExpiry("formData", formData, 50000);
    };

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`FormH.${key}`);
    };

    return (
        <Form onSubmit={submit}>
            <Row>
                <div className="form-header">
                    <div className="form-title">
                        {translate('NumDaysAfterMar31st2021AndBeforeOct1st2021UnableToPerformServices')}

                        <p style={{ fontWeight: "bold", marginTop: "10px" }}>
                            {translate('SeeForInstructions')}
                        </p>
                    </div>
                </div>
            </Row>

            <Row className="date-select-picker">
                <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                    <Form.Select
                        onChange={(e) =>
                        {
                            const updatedFormData = { ...formData, S5_Q1: e.target.value };
                            setFormData(updatedFormData);
                            setWithExpiry("formData", updatedFormData, 50000);
                        }}
                    >
                        <option>{commonTranslate('SelectDays')}</option>
                        {day60
                            .slice()
                            .reverse()
                            .map((val) => (
                                <option key={val} value={val}>{val}</option>
                            ))}
                    </Form.Select>
                    <p className="validation-error">{error.S5_Q1}</p>
                </div>
            </Row>

            <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                <div className="form-button-group mt-3">
                    <Button
                        className="next-button"
                        type="button"
                        onClick={() =>
                        {
                            const newActiveKey = +activeKey - 1;
                            setActiveKey(newActiveKey);
                            setWithExpiry("activeKey", newActiveKey, 50000);
                        }}
                        style={{ backgroundColor: colors.back, borderColor: colors.back, width: 100 }}
                    >
                        {commonTranslate('Back')}
                    </Button>
                    <Button
                        className="next-button text-uppercase"
                        type="submit"
                        style={{ backgroundColor: colors.theme, borderColor: colors.theme }}
                    >
                        {commonTranslate('Next')}
                    </Button>
                </div>
            </Row>

            <Col className="mt-4 upper-divider italics">
                <p>{translate('NumDaysUnableToPerformFromApr1st2021ToSep30th2021')}</p>

                <p>{translate('SonOrDaughterMustGenerallyBeUnder18YearsOfAge')}</p>
            </Col>
        </Form>
    );
}

export default FormH;
