import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import "react-phone-input-2/lib/style.css";
import "./Landing.css";
import { colors, apiURL } from '../config/env';
import { emailRegex, isEmpty, textRegex } from '../component/helper';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import SSNInput from '../component/SSNInput';
import { setWithExpiry, getWithExpiry } from '../component/utility';
import FullScreenSpinner from '../component/FullScreenSpinner';
import { step13InformationValidationLoadedEvent, step13InformationValidationCompletedEvent, step12GovernmentIdentificationCompletedEvent } from '../component/FacebookPixelEvents';

function DLExtractedForm({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() =>
    {
        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    useEffect(() =>
    {
        if (activeKey === 13)
        {
            step12GovernmentIdentificationCompletedEvent();
            step13InformationValidationLoadedEvent();
        }
    }, [activeKey]);

    const validateAll = () =>
    {
        const errors = {};

        if (isEmpty(formData.Primary_First_Name)) formData.Primary_First_Name = formData.First_Name;
        if (isEmpty(formData.Primary_First_Name)) errors.Primary_First_Name = "Enter a value for this field";
        else if (!textRegex.test(formData.Primary_First_Name)) errors.Primary_First_Name = "Name can only contain letters";

        if (isEmpty(formData.Primary_Last_Name)) formData.Primary_Last_Name = formData.Last_Name;
        if (isEmpty(formData.Primary_Last_Name)) errors.Primary_Last_Name = "Enter a value for this field";
        else if (!textRegex.test(formData.Primary_Last_Name)) errors.Primary_Last_Name = "Name can only contain letters";

        if (isEmpty(formData.dl_resp?.husband_data?.Address)) errors.Primary_Address = "Enter a value for this field";


        if (isEmpty(formData.Primary_SSN)) errors.Primary_SSN = "Enter a value for this field";
        if (isEmpty(formData.Confirm_Primary_SSN)) errors.Confirm_Primary_SSN = "Enter a value for this field";
        if (!isEmpty(formData.Primary_SSN) && !isEmpty(formData.Confirm_Primary_SSN) && formData.Primary_SSN !== formData.Confirm_Primary_SSN)
            errors.Confirm_Primary_SSN = "Confirm SSN mismatch";

        if (isEmpty(formData.Primary_Email)) formData.Primary_Email = formData.Email;
        if (isEmpty(formData.Primary_Email)) errors.Primary_Email = "Enter a value for this field";
        else if (!emailRegex.test(formData.Primary_Email)) errors.Primary_Email = "Enter a valid email address. (eg: yourname@domain.com)";

        if (isEmpty(formData.Primary_Phone)) formData.Primary_Phone = formData.Phone;
        if (isEmpty(formData.Primary_Phone)) errors.Primary_Phone = "Enter a valid value for this field.";
        else if (formData.Primary_Phone?.length < 11) errors.Primary_Phone = "Enter a valid value for this field.";

        if (Object.values(errors).find((e) => e))
        {
            setError(errors);
            return false;
        }

        return true;
    };

    const submit = async (e) =>
    {
        e.preventDefault();
        setError({});
        setErrorMessage(null);

        if (!validateAll())
        {
            return;
        }

        try
        {
            setLoading(true);
            const uri = 'https://backend.automatedtaxcredits.com/api/v1/fill_8821';
            const payloadPrimary = {
                Name: `${formData.First_Name} ${formData.Last_Name}`,
                Address: formData.Street_Address,
                City: formData.City,
                State: formData.State,
                Zip: formData.Zip_Code,
                Ssn: formData.Primary_SSN,
                Phone: formData.Phone,
                Email: formData.Email
            };

            const responsePrimary = await axios.post(uri, payloadPrimary, {
                headers: { 'Content-Type': 'application/json' }
            });

            try
            {
                const intake_data = {
                    "Gov_January_1_2021_through_March_31_2021": formData.S3_Q1.length,
                    "Child_January_1_2021_through_March_31_2021": formData.S3_Q2.length,
                    "Gov_April_1_2021_through_September_30_2021": formData.S4_Q2.length,
                    "Child_April_1_2021_through_September_30_2021": formData.S4_Q3.length,
                    "Family_January_1_2021_through_March_31_2021": Number(formData.S4_Q1 || 0),
                    "Family_April_1_2021_through_September_30_2021": Number(formData.S5_Q1 || 0)
                };

                const update_ssn_uri = `https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_ssn_user`;
                const update_ssn_data = {
                    first_name: formData.First_Name,
                    last_name: formData.Last_Name,
                    email: formData.Email,
                    ssn: formData.Primary_SSN,
                    intake_data: JSON.stringify(intake_data)
                };

                await axios.post(update_ssn_uri, update_ssn_data);
            } catch (e)
            {
                const update_ssn_uri = `https://xyrm-sqqj-hx6t.n7c.xano.io/api:jajN3pdi/update_ssn_user`;
                const update_ssn_data = {
                    first_name: formData.First_Name,
                    last_name: formData.Last_Name,
                    email: formData.Email,
                    ssn: formData.Primary_SSN,
                    intake_data: {}
                };

                await axios.post(update_ssn_uri, update_ssn_data);
            }


            if (responsePrimary.status !== 200)
            {
                console.log('Response: ', responsePrimary);

                setLoading(false);
                setErrorMessage('Something went wrong!');

                return;
            }

            // if (!formData.dl_resp?.wife_data)
            // {
            //     setActiveKey(Number(activeKey) + 1);
            //     return;
            // }

            // const payloadPartner = {
            //     Name: `${formData.Partner_First_Name} ${formData.Partner_Last_Name}`,
            //     Address: formData.dl_resp?.wife_data?.Address,
            //     Ssn: formData.Partner_SSN,
            //     Phone: formData.Partner_Phone
            // };

            // const responsePartner = await axios.post(uri, payloadPartner, {
            //     headers: { 'Content-Type': 'application/json' }
            // });

            // if (responsePartner.status !== 200)
            // {
            //     console.log('Response: ', responsePartner);
            //     return;
            // }

            const xano_uri = `https://xyrm-sqqj-hx6t.n7c.xano.io/api:wFpE3Mgi/diysetc_zoho_status?email=${formData.Email}`;

            const resposne_xano = await axios.get(xano_uri);
            const zoho_record_id = resposne_xano.data.zoho_record_id;

            const zoho_uri = `${apiURL}/update_stage/${zoho_record_id}`;

            const zoho_ssn_uri = `${apiURL}/update_ssn/${zoho_record_id}`;

            await axios.post(zoho_ssn_uri, {
                ssn: formData.Primary_SSN
            });

            await axios.post(zoho_uri, {
                stage: 'ID Upload'
            });

            setLoading(false);

            step13InformationValidationCompletedEvent();

            const newActiveKey = Number(activeKey) + 1;
            setActiveKey(newActiveKey);
            setWithExpiry("activeKey", newActiveKey, 50000);
            setWithExpiry("formData", formData, 50000);
        }
        catch (error)
        {
            setLoading(false);
            setErrorMessage(error.message);
            console.error('Error: ', error);
        }
    };

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`DLExtractedForm.${key}`);
    };

    return (
        <Form onSubmit={submit}>
            <h2 className="heading_emp" style={{ marginTop: 20, textAlign: "center" }}>{translate('ConfirmYourInformationIsCorrect')}</h2>

            <div className="mt-4 d-flex justify-content-center">
                <iframe className="vimeo-iframe large-screen" src="https://player.vimeo.com/video/969131603?h=39e455a257&title=0&byline=0&portrait=0" width="640" height="360" allow="autoplay; fullscreen; picture-in-picture"></iframe>
            </div>
            <br></br>
            <p>{translate('PleaseConfirmYourInformationIsCorrect')}</p>

            <h3 className="heading_emp" style={{ marginTop: 20, textAlign: "center" }}>{translate('PleaseConfirmAndValidateData')}</h3>

            <p>{translate('MakeSureItMatches')}</p>

            <Row className="pt-4">
                <div>
                    <span>
                        <strong>{translate('FullName')}</strong>
                    </span>
                </div>
                <Col lg={6} md={12}>
                    <Form.Group className="mb-3" >
                        <Form.Control
                            type="text"
                            placeholder=""
                            value={formData.First_Name}
                            onChange={(e) =>
                                setFormData({ ...formData, Primary_First_Name: e.target.value })
                            }
                        />
                        <Form.Label className="small-font">{translate('FullLegalFirstName')}</Form.Label>
                        <p className="validation-error">{error.Primary_First_Name}</p>
                    </Form.Group>
                </Col>
                <Col lg={6} md={12}>
                    <Form.Group className="mb-3" >
                        <Form.Control
                            type="text"
                            placeholder=""
                            value={formData.Last_Name}
                            onChange={(e) =>
                                setFormData({ ...formData, Primary_Last_Name: e.target.value })
                            }
                        />
                        <Form.Label className="small-font">{translate('FullLegalLastName')}</Form.Label>
                        <p className="validation-error">{error.Primary_Last_Name}</p>
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group >
                <Row>
                    <Col lg={6} md={12}>
                        <div>
                            <span>
                                <strong>{translate('PrimaryMailingAddress')}</strong>
                            </span>
                        </div>
                        <Form.Group className="mb-3" >
                            <Form.Control
                                type="text"
                                value={formData.dl_resp?.husband_data?.Address}
                                onChange={(e) =>
                                {
                                    const data = {
                                        ...formData,
                                        dl_resp: {
                                            ...formData.dl_resp,
                                            husband_data: {
                                                ...formData.dl_resp?.husband_data,
                                                Address: e.target.value
                                            }
                                        }
                                    };
                                    setFormData({ ...data });
                                }}
                            />
                            <p className="validation-error">{error.Primary_Address}</p>
                        </Form.Group>
                    </Col>


                </Row>

                <Row>
                    <Col lg={6} md={12} className="mb-3">
                        <div>
                            <span>
                                <strong>{translate('Ssn')}</strong>
                            </span>
                        </div>

                        <SSNInput

                            mask="999-99-9999"
                            maskChar="X"
                            placeholder="AAA-GG-SSSS"
                            onChange={(e) =>
                                setFormData({ ...formData, Primary_SSN: e.target.value })
                            }
                        />
                        <p className="validation-error">{error.Primary_SSN}</p>
                    </Col>

                    <Col lg={6} md={12} className="mb-3">
                        <div>
                            <span>
                                <strong>{translate('ConfirmSsn')}</strong>
                            </span>
                        </div>

                        <SSNInput
                            mask="999-99-9999"
                            maskChar="X"
                            placeholder="AAA-GG-SSSS"
                            onChange={(e) =>
                                setFormData({ ...formData, Confirm_Primary_SSN: e.target.value })
                            }
                        />
                        <p className="validation-error">{error.Confirm_Primary_SSN}</p>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} md={12}>
                        <div>
                            <span>
                                <strong>{translate('CellPhoneNumber')}</strong>
                            </span>
                        </div>

                        <Form.Group className="mb-3" >
                            <PhoneInput
                                country={"us"}
                                onlyCountries={["us"]}
                                value={formData.Phone}
                                disableDropdown={true}
                                countryCodeEditable={false}
                                onChange={(e) => setFormData({ ...formData, Primary_Phone: e })}
                            />
                            <p className="validation-error">{error.Primary_Phone}</p>
                        </Form.Group>
                    </Col>

                    <Col lg={6} md={12}>
                        <div>
                            <span>
                                <strong>{translate('EmailAddress')}</strong>
                            </span>
                        </div>

                        <Form.Group className="mb-3" >
                            <Form.Control
                                type="text"
                                placeholder=""
                                value={formData.Email}
                                onChange={(e) =>
                                    setFormData({ ...formData, Primary_Email: e.target.value })
                                }
                            />
                            <p className="validation-error">{error.Primary_Email}</p>
                        </Form.Group>
                    </Col>
                </Row>

                {/* {formData.dl_resp?.wife_data && (
                    <React.Fragment>
                        <Row className="pt-4">
                            <div>
                                <span>
                                    <strong>Partner Name</strong>
                                </span>
                            </div>
                            <Col lg={6} md={12}>
                                <Form.Group className="mb-3" >
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        onChange={(e) =>
                                            setFormData({ ...formData, Partner_First_Name: e.target.value })
                                        }
                                    />
                                    <Form.Label className="small-font">First Name</Form.Label>
                                    <p className="validation-error">{error.Partner_First_Name}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={12}>
                                <Form.Group className="mb-3" >
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        onChange={(e) =>
                                            setFormData({ ...formData, Partner_Last_Name: e.target.value })
                                        }
                                    />
                                    <Form.Label className="small-font">Last Name</Form.Label>
                                    <p className="validation-error">{error.Partner_Last_Name}</p>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6} md={12}>
                                <div>
                                    <span>
                                        <strong>Partner SSN</strong>
                                    </span>
                                </div>
                                <Form.Group className="mb-3" >
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        onChange={(e) =>
                                            setFormData({ ...formData, Partner_SSN: e.target.value })
                                        }
                                    />
                                    <p className="validation-error">{error.Partner_SSN}</p>
                                </Form.Group>
                            </Col>

                            <Col lg={6} md={12}>
                                <div>
                                    <span>
                                        <strong>Partner Phone</strong>
                                    </span>
                                </div>
                                <PhoneInput
                                    className="w-100"
                                    country={"us"}
                                    onlyCountries={["us"]}
                                    value={"us"}
                                    disableDropdown={true}
                                    countryCodeEditable={false}
                                    onChange={(e) => setFormData({ ...formData, Partner_Phone: e })}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6} md={12}>
                                <div>
                                    <span>
                                        <strong>Partner Address</strong>
                                    </span>
                                </div>
                                <Form.Group className="mb-3" >
                                    <Form.Control
                                        type="text"
                                        value={formData.dl_resp?.wife_data?.Address}
                                        onChange={(e) =>
                                        {
                                            const data = {
                                                ...formData,
                                                dl_resp: {
                                                    ...formData.dl_resp,
                                                    wife_data: {
                                                        ...formData.dl_resp?.wife_data,
                                                        Address: e.target.value
                                                    }
                                                }
                                            };
                                            setFormData({ ...data });
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6} md={12}>
                                <div>
                                    <span>
                                        <strong>Partner Date of Birth</strong>
                                    </span>
                                </div>
                                <DatePicker
                                    maxDate={new Date()}
                                    selected={formData.dl_resp?.wife_data?.DOB ? new Date(formData.dl_resp?.wife_data?.DOB) : null}
                                    onChange={(date) =>
                                    {
                                        const data = {
                                            ...formData,
                                            dl_resp: {
                                                ...formData.dl_resp,
                                                wife_data: {
                                                    ...formData.dl_resp?.wife_data,
                                                    DOB: date
                                                }
                                            }
                                        };
                                        setFormData({ ...data });
                                    }}
                                    dropdownMode="select"
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                )} */}

                {errorMessage && (
                    <Row>
                        <Alert variant="danger" onClose={() =>
                        {
                            setErrorMessage(null);
                        }} dismissible className="mt-3">
                            <Alert.Heading>{commonTranslate('Error')}</Alert.Heading>
                            <p>
                                {errorMessage}
                            </p>
                        </Alert>
                    </Row>
                )}

                <Row>
                    <div className="form-button-group">
                        <span>&nbsp;</span>
                        <Button className="next-button text-uppercase"
                            type="submit"
                            style={{ backgroundColor: colors.theme, borderColor: colors.theme, width: 100 }}>
                            {commonTranslate('Submit')}
                        </Button>
                    </div>
                </Row>

                {loading && (
                    <Row>
                        <FullScreenSpinner />
                    </Row>
                )}

            </Form.Group>
        </Form>
    );
}

export default DLExtractedForm;
