import React, { useState, useEffect } from "react";
import { Row, Form, Button, Col, Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import { colors } from "../config/env";
import DateDropDown from "../component/DateDropDown";
import { setWithExpiry, getWithExpiry } from "../component/utility";
import { step3PartOneSickLeaveCareYouRequiredLoadedEvent, step3PartOneSickLeaveCareYouRequiredCompletedEvent } from '../component/FacebookPixelEvents';

function FormB({ activeKey, setActiveKey, formData, setFormData })
{
    const { t } = useTranslation();

    const [error, setError] = useState({});

    useEffect(() =>
    {
        step3PartOneSickLeaveCareYouRequiredLoadedEvent();

        const storedFormData = getWithExpiry("formData");
        if (storedFormData)
        {
            setFormData(storedFormData);
        }
    }, [setFormData]);

    const submit = (e) =>
    {
        e.preventDefault();
        setError({});

        const initialError = {};
        if (!formData.S3_Q1 || formData.S3_Q1?.some((d) => !d))
            initialError.S3_Q1 = "Please select the value";

        if (Object.values(initialError).find((e) => e))
        {
            setError(initialError);
        }
        else
        {
            step3PartOneSickLeaveCareYouRequiredCompletedEvent();

            setActiveKey(+activeKey + 1);
            setWithExpiry("activeKey", +activeKey + 1, 50000);
            setWithExpiry("formData", formData, 50000);
        }
    };

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    const translate = (key) =>
    {
        return t(`FormB.${key}`);
    };

    return (
        <Form onSubmit={submit}>
            <Row>
                <div className="form-header">
                    <div className="form-title">
                        {translate('NumDaysAfterDec31st2020AndBeforeApr1st2021')}

                        <p style={{ fontWeight: "bold", marginTop: "10px" }}>
                            {translate('SeeBelowInstructions')}
                        </p>
                    </div>
                </div>
            </Row>
            <Row className="date-select-picker">
                <DateDropDown
                    maxValue={10}
                    startDay={new Date("2021-01-02")}
                    endDay={new Date("2021-04-01")}
                    formData={formData}
                    onChange={(e) => setFormData({ ...formData, S3_Q1: e })}
                    error={error.S3_Q1}
                />
            </Row>
            <Row>
                <div className="form-button-group mt-3">
                    <Button
                        className="next-button"
                        type="button"
                        onClick={() =>
                        {
                            setActiveKey(+activeKey - 1);
                            setWithExpiry("activeKey", 8, 50000);
                        }}
                        style={{ backgroundColor: colors.back, borderColor: colors.back, width: 100 }}
                    >
                        {commonTranslate('Back')}
                    </Button>
                    <Button
                        className="next-button text-uppercase"
                        type="submit"
                        style={{ backgroundColor: colors.theme, borderColor: colors.theme }}
                    >
                        {commonTranslate('Next')}
                    </Button>
                </div>
            </Row>

            <Col className="mt-4 upper-divider italics">
                <p>{translate('SelectNumDayInPeriod')}:</p>

                <ul>
                    <li>{translate('SelectNumDayInPeriod')} </li>
                    <li>{translate('AdvisedByHealthCareProviderToSelfQuarantine')}</li>
                    <li>{translate('ExperiencingSymptomsOfCovid19')}</li>
                </ul>
            </Col>
        </Form>
    );
}

export default FormB;