import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

const LanguageSwitcher = () =>
{
    const { i18n } = useTranslation();

    const handleSelect = (lang) =>
    {
        i18n.changeLanguage(lang);
    };

    return (
        <div style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 1000
        }}>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Language
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleSelect('en')}>English</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSelect('es')}>Español</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default LanguageSwitcher;
