import React from 'react';
import { Row, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Question = ({
    title,
    groupName,
    value,
    onChange,
    values = ['Yes', 'No'],
}) =>
{
    const { t } = useTranslation();

    const classes = {
        fontSize: '15px'
    };

    const commonTranslate = (key) =>
    {
        return t(`Common.${key}`);
    };

    return (
        <Row className='question-group'>
            <div className='question-title'>
                <strong>{title}</strong>
            </div>
            <div key='inline-radio' className='mb-3'>
                {values.map((val, index) => (
                    <Form.Check
                        key={index}
                        classnames={classes}
                        label={commonTranslate(val)}
                        value={val}
                        name={groupName}
                        type='radio'
                        checked={value === val}
                        onChange={(event) => onChange(event)}
                    />
                ))}
            </div>
        </Row>
    );
};

export default Question;
